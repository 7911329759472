export const PARTNERS_CODE_NAME = {
  CAPTAIN_CONTRAT: 'captaincontrat',
  LE_BLOG_DU_DIRIGEANT: 'le_blog_du_dirigeant',
  SHINE: 'shine',
  LEGALSTART: 'legalstart',
  SHINE_PREVI: 'shine_previ',
  SHINE_PREVI_ECOMMERCE: 'shine_previ_e-commerce',
};

export const PARTNERS_NAME = {
  CAPTAIN_CONTRAT: 'CaptainContrat',
  LE_BLOG_DU_DIRIGEANT: 'Le blog du dirigeant',
  SHINE: 'Shine',
  LEGALSTART: 'Legalstart',
  SHINE_PREVI: 'Shine prévi',
  SHINE_PREVI_ECOMMERCE: 'Shine prévi e-commerce',
};
