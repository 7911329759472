import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { RoutingService, URL } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import { SERVICE_STATE } from '@dougs/revenue/services/dto';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';
import { LeadStateService } from '../state';

@Injectable({
  providedIn: 'root',
})
export class IsLeadCompanyWaitingForCreationGuard {
  constructor(
    private readonly leadStateService: LeadStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly userStateService: UserStateService,
    private readonly routingService: RoutingService,
  ) {}

  async canActivate(): Promise<boolean | UrlTree> {
    await this.leadStateService.refreshLead(this.userStateService.activeUser.email);

    if (
      (await toPromise(this.leadStateService.lead$)) &&
      this.companyServicesStateService.services?.accounting.state === SERVICE_STATE.WAITING
    ) {
      return true;
    }

    return this.routingService.createUrl([URL.ACCOUNTING]);
  }
}
