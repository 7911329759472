export enum TAX_REGIME_ENUM {
  IR_2031 = 'IR_2031',
  IR_2035 = 'IR_2035',
  IR_2072 = 'IR_2072',
  IR_LMP = 'IR_LMP',
  IR_LMNP = 'IR_LMNP',
  IR_MICRO_BIC = 'IR_MICRO_BIC',
  IR_MICRO_BNC = 'IR_MICRO_BNC',
  IS = 'IS',
  NA = 'NA',
}

export const taxRegimeMapper = new Map<TAX_REGIME_ENUM, string>();
taxRegimeMapper.set(TAX_REGIME_ENUM.IR_2031, 'ir_2031');
taxRegimeMapper.set(TAX_REGIME_ENUM.IR_2035, 'ir_2035');
taxRegimeMapper.set(TAX_REGIME_ENUM.IR_2072, 'ir_2072');
taxRegimeMapper.set(TAX_REGIME_ENUM.IR_LMP, 'ir_lmp');
taxRegimeMapper.set(TAX_REGIME_ENUM.IR_LMNP, 'ir_lmnp');
taxRegimeMapper.set(TAX_REGIME_ENUM.IR_MICRO_BIC, 'ir_micro_bic');
taxRegimeMapper.set(TAX_REGIME_ENUM.IR_MICRO_BNC, 'ir_micro_bnc');
taxRegimeMapper.set(TAX_REGIME_ENUM.IS, 'is');
taxRegimeMapper.set(TAX_REGIME_ENUM.NA, 'na');

export enum LEGAL_FORM_ENUM {
  ASS = 'ASS',
  EI = 'EI',
  EURL = 'EURL',
  GIE = 'GIE',
  SARL = 'SARL',
  SARL_FAMILLE = 'SARL_FAMILLE',
  SAS = 'SAS',
  SASU = 'SASU',
  SC = 'SC',
  SCI = 'SCI',
  SELARL = 'SELARL',
  SNC = 'SNC',
}

export const legalFormMapper = new Map<LEGAL_FORM_ENUM, string>();
legalFormMapper.set(LEGAL_FORM_ENUM.ASS, 'ass');
legalFormMapper.set(LEGAL_FORM_ENUM.EI, 'ei');
legalFormMapper.set(LEGAL_FORM_ENUM.EURL, 'eurl');
legalFormMapper.set(LEGAL_FORM_ENUM.GIE, 'gie');
legalFormMapper.set(LEGAL_FORM_ENUM.SARL, 'sarl');
legalFormMapper.set(LEGAL_FORM_ENUM.SAS, 'sas');
legalFormMapper.set(LEGAL_FORM_ENUM.SASU, 'sasu');
legalFormMapper.set(LEGAL_FORM_ENUM.SC, 'sc');
legalFormMapper.set(LEGAL_FORM_ENUM.SCI, 'sci');
legalFormMapper.set(LEGAL_FORM_ENUM.SELARL, 'selarl');
legalFormMapper.set(LEGAL_FORM_ENUM.SNC, 'snc');
legalFormMapper.set(LEGAL_FORM_ENUM.SARL_FAMILLE, 'sarl_famille');

export enum VAT_TYPE_ENUM {
  NONE = 'NONE',
  EXEMPTION = 'EXEMPTION',
  SIMPLIFIED = 'SIMPLIFIED',
  STANDARD_QUATERLY = 'STANDARD_QUATERLY',
  STANDARD_MONTHLY = 'STANDARD_MONTHLY',
}

export const vatTypeMapper = new Map<VAT_TYPE_ENUM, string>();
vatTypeMapper.set(VAT_TYPE_ENUM.NONE, 'none');
vatTypeMapper.set(VAT_TYPE_ENUM.EXEMPTION, 'exemption');
vatTypeMapper.set(VAT_TYPE_ENUM.SIMPLIFIED, 'simplified');
vatTypeMapper.set(VAT_TYPE_ENUM.STANDARD_MONTHLY, 'standardMonthly');
vatTypeMapper.set(VAT_TYPE_ENUM.STANDARD_QUATERLY, 'standardQuaterly');

enum LEAD_ACTIVATION_STEPS_ENUM {
  SUBMISSION_RECEIVED = 'SUBMISSION_RECEIVED',
  SUBMISSION_REJECTED = 'SUBMISSION_REJECTED',
  SUBMISSION_ACCEPTED = 'SUBMISSION_ACCEPTED',
  LEAD_ACQUIRED = 'LEAD_ACQUIRED', // the user came on the subscription link
  USER_ACCOUNT_REGISTERED = 'USER_ACCOUNT_REGISTERED',
  BANK_ACCOUNT_REGISTERED = 'BANK_ACCOUNT_REGISTERED',
  MISSION_LETTER_SIGNED = 'MISSION_LETTER_SIGNED',
}

export interface Lead {
  externalId?: string;
  partnerExternalId?: string;
  userId?: number;
  firstName?: string;
  lastName?: string;
  email: string;
  phone?: string;
  hasDefinitiveKbis?: boolean;
  commissionPaid?: boolean;
  activationStatus?: LEAD_ACTIVATION_STEPS_ENUM;
  partnerUuid?: string;
  createdAt?: Date;
  updatedAt?: Date;
  signupUrl?: string;
  companyApeCode?: string;
  companyZipCode?: string;
  companyLegalForm?: LEGAL_FORM_ENUM;
  companyActivity?: string;
  companySiren?: string;
  companyTaxRegime?: TAX_REGIME_ENUM;
  companyVatConfigurationType?: VAT_TYPE_ENUM;
  companyAccountingOpeningDate?: string;
  productTypes: string[];
  partnerName: string;
  partnerCodeName: string;
  partnerOfferName: string;
  partnerOfferCodeName: string;
}
