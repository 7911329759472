import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { LoggerService } from '@dougs/core/logger';
import { StateService } from '@dougs/core/state';
import { Lead } from '../dto';
import { LeadHttpService } from '../http';

interface LeadState {
  lead: Lead;
}

@Injectable({
  providedIn: 'root',
})
export class LeadStateService extends StateService<LeadState> {
  lead$: Observable<Lead> = this.select<Lead>((state) => state.lead);

  constructor(
    private readonly logger: LoggerService,
    private readonly leadHttpService: LeadHttpService,
  ) {
    super();
  }

  private setLead(lead: Lead): void {
    this.setState({ lead });
  }

  async refreshLead(email: string): Promise<void> {
    try {
      const lead: Lead = await lastValueFrom(this.leadHttpService.getLead(email));

      this.setLead(lead);
    } catch (e) {
      this.logger.error(e);
    }
  }
}
